<template>
  <span>
    <component
      v-if="section['cta-before']"
      :is="section['cta-before']"
      :class="'explore__cta-before'"
    ></component>
    <div class="explore-section__item">
      <div class="titulos-badge">
        <div class="titulos-badge__header">
          <h2 class="titulos-badge__head">
            {{ section.title }}
          </h2>
          <span class="badge badge--sm badge--accent" v-if="section.new">¡Nuevo!</span>
        </div>
        <!-- <p class="titulos-badge__p" v-if="section.description">
          {{ section.description }}
        </p> -->
      </div>
      <div class="cs-grid-explora">
        <div class="cs-grid-explora__grid" :class="section.classProducts">
          <template v-for="product in gridProducts">
            <product-card
              v-if="!product.hero"
              :product="product"
              :section="section"
              :key="section.id + '_product_card' + product.id"
            ></product-card>
            <product-card-hero
              v-else
              :product="product"
              :section="section"
              :key="'product_hero_card' + product.id"
            ></product-card-hero>
          </template>
        </div>
        <div class="cs-grid-explora__actions">
          <button
            v-if="showMore"
            class="button button--small button-outline-grey-dark"
            @click="fMore()"
          >
            Ver más
          </button>
          <template v-else>
            <button
              v-if="showLess"
              class="button button--small button-outline-grey-dark"
              @click="less()"
            >
              Ver menos
            </button>
          </template>
        </div>

        <component v-if="section['cta-middle']" :is="section['cta-middle']"></component>
        <div class="cs-grid-explora__cta-caja" v-if="section['cta-after']">
          <component
            v-if="section['cta-after']"
            :is="section['cta-after']"
            :class="'explore__cta-after'"
          ></component>
        </div>

        <!-- Explora posts relacionats  -->
        <section class="cs-grid-explora__related" :class="section.classPosts">
          <!-- Posts relacionats, 1 post -->
          <template v-if="gridPosts.length == 1">
            <template v-for="post in gridPosts">
              <blog-post-card
                v-if="!post.hero"
                :post="post"
                :key="'section_' + section.id + '_blog_post' + post.slug"
                class="thumb-post-constructor--hero"
                @clicked="onClickPost"
              ></blog-post-card>
            </template>
          </template>

          <!-- Posts relacionats, 2 post2 -->
          <template v-else>
            <template v-for="post in gridPosts">
              <blog-post-card
                v-if="!post.hero"
                :post="post"
                :key="'section_' + section.id + '_blog_post' + post.slug"
                @clicked="onClickPost"
              ></blog-post-card>
            </template>
            <!-- <template v-for="post in gridPosts">
              <blog-post-card
                v-if="!post.hero"
                :post="post"
                :key="'section_' + section.id + '_blog_post' + post.slug"
                class="thumb-post--land"
                @clicked="onClickPost"
              ></blog-post-card>
            </template> -->
          </template>

          <!-- <hr style="border-color: red; background: red; height: 40px" />

          <template v-for="post in gridPosts">
            <blog-post-card
              v-if="!post.hero"
              :post="post"
              :key="'section_' + section.id + '_blog_post' + post.slug"
              :class="post.class"
              @clicked="onClickPost"
            ></blog-post-card>
            <blog-post-card-hero
              v-else
              :post="post"
              :key="'section_' + section.id + '_blog_post_' + post.slug"
              @clicked="onClickPost"
            ></blog-post-card-hero>
          </template> -->
        </section>
        <!-- <section class="cs-grid-explora__grid" :class="section.classPosts">
          <template v-for="post in gridPosts">
            <blog-post-card
              v-if="!post.hero"
              :post="post"
              :key="'section_' + section.id + '_blog_post' + post.slug"
              :class="post.class"
              @clicked="onClickPost"
            ></blog-post-card>
            <blog-post-card-hero
              v-else
              :post="post"
              :key="'section_' + section.id + '_blog_post_' + post.slug"
              @clicked="onClickPost"
            ></blog-post-card-hero>
          </template>
          <div />
        </section> -->
      </div>
    </div>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ExploreSection",
  components: {
    ProductCard: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductCard" */ "./ProductCard"
      ),
    ProductCardHero: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductCardHero" */ "./ProductCardHero"
      ),
    BlogPostCard: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "BlogPostCard" */ "./BlogPostCard"
      ),
    BlogPostCardHero: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "BlogPostCardHero" */ "./BlogPostCardHero"
      ),
    CtaChildrenStories: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaChildrenStories" */ "./CtaChildrenStories"
      ),
    CtaArrullos: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaArrullos" */ "./CtaArrullos"
      ),
    CtaChupetes: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaChupetes" */ "./CtaChupetes"
      ),
    CtaHamacaBabybjorn: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaHamacaBabybjorn" */ "./CtaHamacaBabybjorn"
      ),
    CtaLibroLucia: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaLibroLucia" */ "./CtaLibroLucia"
      ),
    CtaPerfectPrep: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaPerfectPrep" */ "./CtaPerfectPrep"
      ),
    CtaMoisesSleepe: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaMoisesSleepe" */ "./CtaMoisesSleepe"
      ),
    CtaRobot: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "CtaRobot" */ "./CtaRobot"),
    CtaExtractorPhilipsAvent: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaExtractorPhilipsAvent" */ "./CtaExtractorPhilipsAvent"
      ),
    CtaActividadesBrightStarts: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "CtaActividadesBrightStarts" */ "./CtaActividadesBrightStarts"
      ),
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      more: false,
    };
  },
  computed: {
    ...mapGetters({
      openSections: "openSections",
    }),
    showMore() {
      return !this.more && this.section.products.length > this.numberOfProductsInGrid;
    },
    showLess() {
      return this.more;
    },
    gridProducts() {
      return this.more
        ? this.section.products
        : this.section.products.slice(0, this.numberOfProductsInGrid);
    },
    numberOfProductsInGrid() {
      let number = 10;
      const heroSections = this.section.products
        .slice(0, 9)
        .filter((product) => product.hero).length;
      if (heroSections > 0) {
        number = number - heroSections;
      }
      return number;
    },
    gridPosts() {
      return this.section.posts;
    },
  },
  methods: {
    less() {
      this.more = false;
      this.removeOpenSections(this.section);
    },
    fMore() {
      this.more = true;
      this.$store.commit("addOpenSection", this.section.id);
    },
    removeOpenSections(section) {
      this.$store.commit("removeOpenSection", section.id);
    },
    onClickPost(value) {
      window.mixpanel.track("Click element constructor", {
        Name: value,
        Section: this.section.title,
        "Section number": this.section.id,
        Type: "Banner - Horizontal",
        Content: "Education",
      });
    },
  },
  mounted() {
    if (this.openSections.includes(this.section.id)) {
      this.more = true;
    }
  },
};
</script>
